/* global moment */
const NOTIFICATION_LIMIT = 15

/** @ngInject */
function NotificationUtils ($rootScope, nextplusSocket) {
  const init = async nextplusSocket => {
    await nextplusSocket.promiseState
    $rootScope.notifications = []
    nextplusSocket.angularSocket.on('notification:list', notifications => {
      if (notifications && notifications.length >= 0) {
        if (notifications.length < NOTIFICATION_LIMIT) {
          $rootScope.hideNotificationLoadMore = true
        }

        notifications.forEach(addNotificationToList)
      }
    })

    nextplusSocket.angularSocket.on('notification:new', addNotificationToList)
  }
  const addNotificationToList = notification => {
    try {
      const notificationIds = $rootScope.notifications.map(
        notification => notification.id
      )
      if (!notificationIds.includes(notification.id)) {
        notification.timePassed = moment(notification.created)
          .locale($rootScope.currentLang || $rootScope.appSettings.language)
          .fromNow()
        $rootScope.notifications.push(notification)
      }
    } catch (error) {
      console.error('Error adding notification to list', error)
    }
  }
  const markNotificationsAsClicked = notification => {
    nextplusSocket.angularSocket.emit(
      'notification:markAsClicked',
      notification
    )
  }
  const markNotificationsAsRead = notifications => {
    nextplusSocket.angularSocket.emit('notification:markAsRead', notifications)
  }
  const loadMore = data =>
    nextplusSocket.angularSocket.emit('notification:list', data)
  return {
    markNotificationsAsClicked,
    markNotificationsAsRead,
    init,
    loadMore
  }
}

module.exports = NotificationUtils
