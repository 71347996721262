/* global _ kendo angular */
import priorityConstants from '../modules/main/workorder/priorityConstants'
import QuantitySelectorDialog from '../modules/main/workorder/quantity-selector/quantity-selector.dialog.controller.js'
const statusColorHelper = require('../../../../common/services/status-color-helper')
const debug = require('debug')('nextplus:workorder-utils-service')

/** @ngInject */
function WorkorderUtils (
  $rootScope,
  $translate,
  htmlWork,
  PermissionUtils,
  Workorder,
  DialogService,
  KendoDataSourceHelper,
  Group,
  UserModel,
  WorkflowType,
  DateTimeFormatService,
  $mdDialog,
  $mdToast
) {
  const hasDeletePermissions = PermissionUtils.isPermit(
    'Workorder',
    'deleteById'
  )

  const priorityOptions = priorityConstants.map(priority => {
    return {
      id: priority.value,
      name: $translate.instant(priority.string),
      icon: priority.icon,
      color: priority.color
    }
  })

  const priorityNameById = _.keyBy(priorityOptions, 'id')

  const generateWorkorderColumns = function generateWorkorderColumns (
    $scope,
    allLocations,
    users,
    workorderStatuses,
    visibleColumnObject = null
  ) {
    const stations = []
    const locations = []

    allLocations.forEach(location => {
      stations.push({
        id: location.id,
        name: location.name
      })
      if (!_.isNil(location.otherId)) {
        locations.push({
          id: location.name,
          name: location.name
        })
      }
    })

    const validWorkorderStatuses = workorderStatuses.filter(
      workorderStatus => !workorderStatus.deletedAt
    )

    const validWorkorderStatusesById = _.keyBy(validWorkorderStatuses, 'id')

    const tableColumns = [
      // WORKORDER_NUMBER
      {
        uniqueId: '8455275c-911a-403c-b6e6-413100c870ef',
        field: 'workorderNumber',
        locked: true,
        translateCode: 'WO.workorderNumber',
        trustedTemplate: data => {
          const id = data.id
          const workorderNumber = htmlWork.htmlEncode(data.workorderNumber)
          return `<a data-testid="workorder-${id}"
          ui-sref="app.workorder.show({id:'${id}'})">${workorderNumber}</a>`
        }
      },
      // WORKORDER_NAME
      {
        uniqueId: '129c5220-6759-4bc0-a7a2-252032542c15',
        field: 'name',
        translateCode: 'WO.workorderName',
        hidden: true
      },
      // SKU
      {
        uniqueId: '7e9c5220-2259-4bc0-a7a2-722032542b92',
        field: 'sku',
        translateCode: 'WO.sku',
        type: 'string'
        // media: '(min-width: 768px)',
      },
      // SERIALS
      {
        uniqueId: 'afe5f961-d755-4f43-b51c-60ae88c467b9',
        field: 'serials',
        title: '',
        width: '300px',
        translateCode: 'WO.SERIALS',
        trustedTemplate: data => {
          const htmlSerials = []
          if (_.isNil(data.serials)) {
            return ''
          }
          if (typeof data.serials === 'string') {
            data.serials = data.serials.split(',')
          }
          data.serials.forEach(serial => {
            htmlSerials.push(
              `<a ui-sref='app.workorder.show({id: "${
                data.id
              }",serial:"${htmlWork.escapeHTMLQuotes(
                serial
              )}"})'>${htmlWork.htmlEncode(serial)}</a>`
            )
          })
          return htmlSerials.join(', ')
        }
      },
      // PART_DESC
      {
        uniqueId: 'ca8b8edc-1ce2-4c7b-9aa5-6ec5b67bc631',
        field: 'partDesc',
        translateCode: 'WO.partDesc'
      },
      // PART_DESC
      {
        uniqueId: 'd1165db0-4b59-4bbc-9387-beb5d52a566c',
        field: 'productRev',
        translateCode: 'WO.productRev'
      },
      // IS SERIAL
      {
        uniqueId: 'd1165xb0-4b59-4bbc-9387-beb5d52a566c',
        field: 'isSerial',
        translateCode: 'WO.isSerial',
        type: 'boolean',
        hidden: true,
        filterable: true
      }, // CLOSED
      {
        uniqueId: 'd1165618-4b59-4bbc-9387-beb5d52a566c',
        field: 'closed',
        translateCode: 'WO.closed',
        type: 'boolean',
        hidden: true,
        filterable: true
      },
      // ERP CLOSED
      {
        uniqueId: 'd1165619-4b59-4bbc-9387-beb5d52a566c',
        field: 'erpClosed',
        translateCode: 'WO.erpClosed',
        type: 'boolean',
        hidden: true,
        filterable: true
      },
      // QUANTITY
      {
        uniqueId: 'e5d610a9-9fb3-4d1b-9a2b-2059cf85564d',
        field: 'quantity',
        translateCode: 'WO.quantity',
        type: 'number',
        // media: '(min-width: 768px)',
        filterable: true,
        trustedTemplate: data => {
          let quantity = 0
          if (data.stocks?.length > 0) {
            data.stocks
              .filter(stock => stock.end !== null)
              .forEach(stock => {
                quantity += stock.quantity
              })
          } else {
            data.sessions
              .filter(session => session.end !== null)
              .forEach(session => {
                quantity += session.quantity
              })
          }
          return `<div>${quantity} / ${htmlWork.htmlEncode(
            data.quantity
          )}</div>`
        }
      },
      // BALANCE
      {
        uniqueId: 'dc0dd375-c52d-48f4-aacc-9196ff7ae1f8',
        field: 'balance',
        translateCode: 'WO.AVAILABLE_INVENTORY',
        type: 'number',
        filterable: true
      },
      // VALID_QUANTITY
      {
        uniqueId: 'cbe473cd-b4c9-493d-afa6-61a7a250834c',
        field: 'validQuantity',
        translateCode: 'WO.VALID_QUANTITY',
        type: 'number',
        filterable: true
      },
      // INVALID_QUANTITY
      {
        uniqueId: 'daa1dc3f-f2f5-4585-a32a-9de4aa4fe68a',
        field: 'invalidQuantity',
        translateCode: 'WO.INVALID_QUANTITY',
        type: 'number',
        // media: '(min-width: 768px)',
        filterable: true
      },
      // EXPECTED_DATE
      {
        uniqueId: 'e946db16-8888-43c2-8e28-5201660c95c8',
        field: 'expectedDate',
        translateCode: 'WO.expectedDate',
        type: 'date',
        filterable: true,
        // media: '(min-width: 768px)',
        template: data => {
          return data.expectedDate === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.expectedDate, 'date')
        }
      },
      // WORK_CENTER
      {
        uniqueId: '5e0599df-d790-4ede-a068-6794e7054d41',
        field: '_actions.workCenter',
        translateCode: 'WO.workCenter',
        type: 'string',
        values: locations.map(o => ({ text: o.name, value: o.id })),
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: locations
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        // media: '(min-width: 768px)',
        template: data => {
          if (!['priority', 'sap'].includes(data.source)) return 'N/A'
          const text = []
          data._actions.forEach(action => {
            if (action.workCenter) {
              text.push(action.workCenter)
            }
          })
          return text.join(', ')
        }
      },
      // LOCATION_IDS
      {
        uniqueId: '3e8df241-50eb-4348-a71e-ed9bfbb85f92',
        field: 'locationIds',
        translateCode: 'WO.LOCATIONS',
        type: 'string',
        values: stations.map(o => ({ text: o.name, value: o.id })),
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: stations
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        // media: '(min-width: 768px)',
        template: data => {
          const text = []
          if (_.isNil(data.locationIds)) {
            return ''
          }
          if (typeof data.locationIds === 'string') {
            data.locationIds = data.locationIds.split(',')
          }
          data.locationIds.forEach(locationId => {
            const location = _.find($rootScope.locations, {
              id: locationId
            })
            if (!_.isUndefined(location)) text.push(location.name)
          })
          return text.join(', ')
        }
      },
      // KIT_STATUS
      {
        uniqueId: '20f82b9d-6406-4000-ba4e-0b108e3e664f',
        field: 'missingKit',
        translateCode: 'WO.kitStatus',
        type: 'boolean',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let sufficientQuantity = data.quantity
          let color = 'green-500-fg'
          if (['priority', 'sap'].includes(data.source)) {
            sufficientQuantity = data.issueSufficientQuantity
            if (data.missingKit === 'true' || data.missingKit === true) {
              color = 'red-500-fg'
            }
          }
          const html = `<div class="kitStatus text-center">
                <md-button class="md-icon-button" ng-click="showKit('${
                  data.id
                }')"><md-icon md-font-icon="icon-checkbox-blank-circle" class="${color} s24" /></md-button>
                <div style="position: relative;top: -4px;">${sufficientQuantity} / ${htmlWork.htmlEncode(
            data.quantity
          )}</div>
              </div>`
          return html
        }
      },
      // PRIORITY
      {
        uniqueId: '34ceb533-6f33-46a1-b69f-8956c3e8f4ef',
        field: 'priority',
        translateCode: 'WO.PRIORITY',
        type: 'number',
        values: priorityOptions.map(o => ({ text: o.name, value: o.id })),
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: priorityOptions
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          const priorityObject = priorityNameById[data.priority]
          return _.isNil(priorityObject)
            ? ''
            : `<div layout="row" layout-align="start center">
                    <md-icon style="margin: 0; margin-inline-end: 8px; color: ${htmlWork.escapeHTMLQuotes(
                      priorityObject.color
                    )};" class="s20" md-font-icon="${htmlWork.escapeHTMLQuotes(
                priorityObject.icon
              )}"></md-icon>
                    <span>${htmlWork.htmlEncode(priorityObject.name)}</span>
                  </div>`
        }
      },
      // CREATED
      {
        uniqueId: '083d3d76-84a9-4873-b846-ccba3935344f',
        field: 'created',
        translateCode: 'COMMON.CREATED',
        type: 'date',
        filterable: true,
        hidden: true,
        template: data => {
          return !data.created
            ? '--'
            : DateTimeFormatService.formatDateTime(data.created, 'date')
        }
      },
      // START
      {
        uniqueId: '2e9d90e6-42eb-4792-8044-8821d6e7016b',
        field: 'start',
        translateCode: 'WO.start',
        type: 'date',
        filterable: true,
        hidden: true,
        // media: '(min-width: 768px)',
        template: data => {
          return !data.start
            ? '--'
            : DateTimeFormatService.formatDateTime(data.start, 'date')
        }
      },
      // END
      {
        uniqueId: '748578cd-cc1a-4e50-8c07-a4639bac66f6',
        field: 'end',
        translateCode: 'WO.end',
        type: 'date',
        filterable: true,
        hidden: true,
        // media: '(min-width: 768px)',
        template: data => {
          return !data.end
            ? '--'
            : DateTimeFormatService.formatDateTime(data.end, 'date')
        }
      },
      // ERP_PRODUCTION_START_DATE
      {
        uniqueId: '33292df0-695a-4539-85a3-f6fcf26e013d',
        field: 'erpProductionStartDate',
        translateCode: 'WO.ERP_PRODUCTION_START_DATE',
        type: 'date',
        filterable: true,
        hidden: true,
        // media: '(min-width: 768px)',
        template: data => {
          return !data.erpProductionStartDate
            ? '--'
            : DateTimeFormatService.formatDateTime(
                data.erpProductionStartDate,
                'date'
              )
        }
      },
      // ERP_PRODUCTION_END_DATE
      {
        uniqueId: '4c309131-556d-4a49-a8ef-ae7e66776b2c',
        field: 'erpProductionEndDate',
        translateCode: 'WO.ERP_PRODUCTION_END_DATE',
        type: 'date',
        filterable: true,
        hidden: true,
        // media: '(min-width: 768px)',
        template: data => {
          return !data.erpProductionEndDate
            ? '--'
            : DateTimeFormatService.formatDateTime(
                data.erpProductionEndDate,
                'date'
              )
        }
      },
      // STATUS
      {
        uniqueId: 'acfbc45a-2d03-420c-8d1b-d2bbcccd2ec5',
        field: 'statusId',
        translateCode: 'WO.STATUS',
        values: validWorkorderStatuses.map(o => ({
          text: o.name,
          value: o.id
        })),
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: validWorkorderStatuses
                }),
                valuePrimitive: true
              })
            }
          }
        },
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let html = '<span>'
          if (validWorkorderStatusesById[data.statusId]) {
            html += generateStatusTemplate(
              data.statusName,
              validWorkorderStatusesById[data.statusId].color
            )
          } else {
            html += data.statusName
          }
          html += '</span><div class="tags">'
          if (!_.isUndefined(data.tags)) {
            data.tags.forEach(tag => {
              html +=
                '<span class="tag">' + htmlWork.htmlEncode(tag) + '</span>'
            })
          }
          html += '</div></span>'
          return html
        }
      },
      // ERP_STATUS
      {
        uniqueId: 'f31c515f-6a57-4f8d-8eb6-0c006853fb6f',
        field: 'erpStatus',
        translateCode: 'WO.ERP_STATUS',
        hidden: true,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let html = '<span>'
          html += data.erpStatus ? htmlWork.htmlEncode(data.erpStatus) : ''
          html += '</span><div class="tags">'
          if (!_.isUndefined(data.tags)) {
            data.tags.forEach(tag => {
              html +=
                '<span class="tag">' + htmlWork.htmlEncode(tag) + '</span>'
            })
          }
          html += '</div>'
          return html
        }
      },
      // RMA_NUMBER
      {
        uniqueId: 'a9969f87-3dcc-4b06-ba18-6135f2dc9e71',
        field: 'rmaNumber',
        translateCode: 'WO.RMA_NUMBER',
        hidden: true
      },
      // CUSTOMER_DIAGNOSIS
      {
        uniqueId: '8d54f357-9d19-4228-9386-71901ceb0510',
        field: 'customerDiagnosis',
        translateCode: 'WO.CUSTOMER_DIAGNOSIS',
        hidden: true
      },
      // CALL_TYPE
      {
        uniqueId: '4bc80415-5ba5-43fe-8b8b-8d1059870764',
        field: 'callType',
        translateCode: 'WO.CALL_TYPE',
        hidden: true
      },
      // SERVICE_TYPE
      {
        uniqueId: 'c9c3a90b-8cd0-4df6-986c-ed6fc29b6b44',
        field: 'serviceType',
        translateCode: 'WO.SERVICE_TYPE',
        hidden: true
      },
      // WORKFLOW_NAME
      {
        uniqueId: '31fe2961-0f58-2360-8b61-b1302e223a41',
        field: 'workflowName',
        translateCode: 'WO.WORKFLOW_NAME',
        hidden: true,
        template: data => {
          if (!data || !data.workflowName) return '--'
          return data.workflowName
        }
      },
      // SOURCE
      {
        uniqueId: '92de2962-0f58-4360-8b61-c1302e623a77',
        field: 'source',
        translateCode: 'WO.SOURCE',
        hidden: true
      },
      // TYPE
      {
        uniqueId: '60f888d5-5bc7-4022-8850-e4854ac09c03',
        field: 'workorderTypeId',
        translateCode: 'WO.TYPE',
        type: 'string',
        hidden: true,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new KendoDataSourceHelper.KendoNextplusDataSource({
                  serverFiltering: true,
                  find: WorkflowType.find,
                  count: WorkflowType.count
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          if (!data.workorderTypeId) return '--'
          return data.workorderType?.name
        }
      },
      // CUSTOMER - ACCOUNT_NAME
      {
        uniqueId: '902d3955-9ff3-4aff-8838-4032dcc9de75',
        field: 'customer.accountName',
        translateCode: 'WO.CUSTOMER.ACCOUNT_NAME',
        hidden: true,
        template: data => {
          if (!data.customer || !data.customer.accountName) return '--'
          return data.customer.accountName
        }
      },
      // CUSTOMER - ACCOUNT_NUMBER
      {
        uniqueId: '2b02711e-2b8b-487a-9356-94e48a54c8f0',
        field: 'customer.accountNumber',
        translateCode: 'WO.CUSTOMER.ACCOUNT_NUMBER',
        hidden: true,
        template: data => {
          if (!data.customer || !data.customer.accountNumber) return '--'
          return data.customer.accountNumber
        }
      },
      // CUSTOMER - ADDRESS
      {
        uniqueId: 'e5f6fabd-45b9-405f-b1a6-4352e75e1092',
        field: 'customer.address',
        translateCode: 'WO.CUSTOMER.ADDRESS',
        hidden: true,
        template: data => {
          if (!data.customer || !data.customer.address) return '--'
          return data.customer.address
        }
      },
      // CUSTOMER - CITY
      {
        uniqueId: '1ec00624-1885-43b2-91bc-58fb69c83d34',
        field: 'customer.city',
        translateCode: 'WO.CUSTOMER.CITY',
        hidden: true,
        template: data => {
          if (!data.customer || !data.customer.city) return '--'
          return data.customer.city
        }
      },
      // CUSTOMER - PHONE
      {
        uniqueId: '433899a0-908e-4d24-a522-c0886770f1f2',
        field: 'customer.phone1',
        translateCode: 'WO.CUSTOMER.PHONE',
        hidden: true,
        template: data => {
          if (!data.customer || !data.customer.phone1) return '--'
          return data.customer.phone1
        }
      },
      {
        uniqueId: '27b413a2-fa95-4e01-b889-b815cf594a3e',
        field: 'project.description',
        translateCode: 'WO.project.description',
        hidden: true,
        template: data => {
          if (!data.project || !data.project.description) return '--'
          return data.project.description
        }
      },

      // extraProperties - AGENT_NAME
      {
        uniqueId: '36c78d50-2cbd-4b10-ad04-257c00e63643',
        field: 'extraProperties.agentName',
        translateCode: 'WO.AGENT_NAME',
        hidden: true,
        template: data => {
          if (!data.extraProperties || !data.extraProperties.agentName) {
            return '--'
          }
          return data.extraProperties.agentName
        }
      },
      // ORDER_NUMBER
      {
        uniqueId: '0ad666c2-2d69-477e-8775-2e2446adba5b',
        field: 'orderNumber',
        translateCode: 'WO.SALE_ORDER',
        hidden: true,
        template: data => {
          if (!data.orderNumber) return '--'
          return data.orderNumber
        }
      },
      // ORDER_NUMBER_LINE
      {
        uniqueId: '304ef30f-e80d-4d8e-8c58-f85629ed457d',
        field: 'orderNumberLine',
        translateCode: 'WO.SALE_ORDER_LINE',
        hidden: true,
        template: data => {
          if (!data.orderNumberLine) return '--'
          return data.orderNumberLine
        }
      },
      // PARENT_WORKORDER_NUMBER
      {
        uniqueId: 'c728274d-a73e-46b4-95d7-d6f1e292755d',
        field: 'parentWorkorderNumber',
        translateCode: 'WO.parentWorkorderNumber',
        hidden: true,
        template: data => {
          if (!data.parentWorkorderNumber) return '--'
          return data.parentWorkorderNumber
        }
      },
      // PURCHASE_ORDER_NUMBER
      {
        uniqueId: '1e0826a8-11bd-40e1-93ea-286cd5459529',
        field: 'purchaseOrderNumber',
        translateCode: 'WO.PURCHASE_ORDER_NUMBER',
        hidden: true,
        template: data => {
          if (!data.purchaseOrderNumber) return '--'
          return data.purchaseOrderNumber
        }
      },
      // PURCHASE_ORDER_LINE
      {
        uniqueId: 'beed0aa4-4129-48db-8366-32aedb14a0bd',
        field: 'purchaseOrderLine',
        translateCode: 'WO.PURCHASE_ORDER_LINE',
        hidden: true,
        template: data => {
          if (!data.purchaseOrderLine) return '--'
          return data.purchaseOrderLine
        }
      },
      // NEXT_NODE_NAMES
      {
        uniqueId: '0e8d89e2-e4c0-401f-87bc-a23966fb4718',
        field: 'nextNodeNames',
        translateCode: 'WO.NEXT_NODES',
        type: 'array',
        hidden: true,
        template: data => {
          if (!data.nextNodeNames) {
            return '--'
          }
          const nodeNames = data.nextNodeNames.toJSON()
          if (nodeNames.length === 0) return '--'
          return nodeNames.join(', ')
        }
      },
      // NEXT_CHAPTER_NAMES
      {
        uniqueId: '1e8d89e2-e4c0-401f-87bc-a23966fb4748',
        field: 'nextChapterNames',
        translateCode: 'WO.NEXT_CHAPTER_NAMES',
        type: 'array',
        hidden: true,
        template: data => {
          if (!data.nextChapterNames) {
            return '--'
          }
          const nextChapterNames = data.nextChapterNames.toJSON()
          if (nextChapterNames.length === 0) return '--'
          return nextChapterNames.join(', ')
        }
      },
      // GROUPS
      {
        uniqueId: 'bdae0569-b006-43a0-aa93-a0da51bb07e7',
        field: 'groups',
        translateCode: 'USER.GROUPS',
        type: 'array',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new KendoDataSourceHelper.KendoNextplusDataSource({
                  serverFiltering: true,
                  find: Group.find,
                  count: Group.count
                }),
                valuePrimitive: true
              })
            }
          }
        },
        hidden: true,
        template: data => {
          return data.groupModel.map(g => g.name).join(', ')
        }
      },
      // ACTIVE_USERS
      {
        uniqueId: '36b5aacf-158d-4e83-b7a2-f9724716f42e',
        field: 'activeUserIds',
        translateCode: 'WO.ACTIVE_USERS',
        type: 'array',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new KendoDataSourceHelper.KendoNextplusDataSource({
                  serverFiltering: true,
                  find: UserModel.find,
                  count: UserModel.count
                }),
                valuePrimitive: true
              })
            }
          }
        },
        hidden: true,
        trustedTemplate: data => {
          if (!data.activeUserIds || data.activeUserIds.length === 0) {
            return '--'
          }
          $scope.usersPerRow[data.id] = _.compact(
            data.activeUserIds.map(userId => {
              const user = users.find(u => u.id === userId)
              if (!user) {
                return null
              }
              return { id: user.id, name: user.displayName }
            })
          )
          return `<users-avatar max-users="3" users="usersPerRow['${data.id}']"></users-avatar>`
        }
      },
      // ASSIGNED_USERS
      {
        uniqueId: '03b4f68b-4099-4a51-a123-347b0ce4d41b',
        field: 'assignedUsers',
        translateCode: 'WO.ASSIGNED_USERS',
        type: 'array',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new KendoDataSourceHelper.KendoNextplusDataSource({
                  serverFiltering: true,
                  find: UserModel.find,
                  count: UserModel.count
                }),
                valuePrimitive: true
              })
            }
          }
        },
        hidden: true,
        trustedTemplate: data => {
          if (!data.assignedUsers || data.assignedUsers.length === 0) {
            return '--'
          }
          $scope.usersPerRow[data.id] = _.compact(
            data.assignedUsers.map(userId => {
              const user = users.find(u => u.id === userId)
              if (!user) {
                return null
              }
              return { id: user.id, name: user.displayName }
            })
          )
          return `<users-avatar max-users="3" users="usersPerRow['${data.id}']"></users-avatar>`
        }
      },
      // ACTIONS
      {
        uniqueId: '0c5e9869-55b8-4381-8d45-539b625f3249',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">
                        <md-button  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                                    ui-sref="app.workorder.show({id:'${data.id}'})"
                                    ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                                    data-testid="show-${data.id}">
                            <md-icon md-font-icon="icon-eye" class="s20"></md-icon>
                        </md-button>`
          if (data.source === 'local' && hasDeletePermissions) {
            html += `
                <div class="hover-buttons-set">
                    <md-button  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                ng-click="deleteWorkorder('${
                                  data.id
                                }','${htmlWork.escapeHTMLQuotes(
              data.workorderNumber
            )}',$event)"
                                data-testid="delete-${data.id}">
                        <md-icon md-font-icon="icon-delete" class="s20"></md-icon>
                    </md-button>
                </div>`
          }
          html += '</div>'
          return html
        }
      }
    ]

    if ($rootScope.appSettings.supplierWorkorderAutoLinkToGroups) {
      tableColumns.splice(
        1,
        0, // SUPPLIER
        {
          uniqueId: '8b746932-d685-41f7-bc5d-0714d1531327',
          field: 'supplier.description',
          translateCode: 'WO.SUPPLIER',
          template: data => {
            if (data.supplier && data.supplier.description) {
              return data.supplier.description
            }
            return ''
          }
        }
      )
    }

    if (visibleColumnObject) {
      for (let i = 0; i < tableColumns.length; i++) {
        const column = tableColumns[i]
        column.hidden = !visibleColumnObject[column.field]
      }
    }

    return tableColumns
  }
  const generateStatusTemplate = function generateStatusTemplate (
    status,
    color
  ) {
    const backgroundColor = color
    const textColor = statusColorHelper.getReadableFontColor(backgroundColor)
    return `<div style="white-space: nowrap; text-overflow: ellipsis;">
                  <span class="form-status" style="background-color:${htmlWork.escapeHTMLQuotes(
                    backgroundColor
                  )}; color:${textColor}">${htmlWork.htmlEncode(status)}</span>
                </div>`
  }
  const deleteWorkorder = function deleteWorkorder (
    workorderId,
    workorderNumber
  ) {
    return new Promise((resolve, reject) => {
      const extraFields = [
        {
          key: 'relatedModelsDeletion',
          type: 'checkbox',
          className: 'layout-row',
          templateOptions: {
            label: $translate.instant('WO.RELATED_MODELS_DELETION')
          }
        }
      ]
      const extraFieldsObj = {
        extraFieldsFormly: extraFields,
        extraFieldsModel: {
          relatedModelsDeletion: true
        }
      }
      DialogService.deleteDialog(
        $translate.instant('WO.REAL_DELETE', {}, null, null, 'sceParameters'),
        workorderNumber,
        extraFieldsObj
      ).then(async function ({ extraFieldsModel }) {
        $rootScope.loadingProgress = true
        try {
          await Workorder.deleteWorkorder({
            workorderId,
            relatedModelsDeletion: extraFieldsModel.relatedModelsDeletion
          }).$promise
          debug(`Workorder '${workorderId}' deleted successfully`)
          $rootScope.loadingProgress = false
          resolve()
        } catch (e) {
          $rootScope.loadingProgress = false
          console.error(e)
          if (e?.data?.error?.details?.stockDetails) {
            $rootScope.showErrorToast(
              $translate.instant(e?.data?.error?.code, {
                stockDetails: e?.data?.error?.details?.stockDetails
              })
            )
          } else {
            $rootScope.showErrorToast($translate.instant(e?.data?.error?.code))
          }
          reject(e)
        }
      })
    })
  }

  // available modes - edit, split, start
  const splitItemsDialog = function splitItemsDialog (items, sku, mode) {
    return $mdDialog
      .show({
        /** @ngInject */
        controller: QuantitySelectorDialog,
        template: require('../modules/main/workorder/quantity-selector/quantity-selector.dialog.template.html'),
        locals: {
          items,
          mode
        },
        multiple: false,
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedPart: PartAssembly =>
            PartAssembly.findOne({
              filter: {
                where: {
                  number: sku
                },
                fields: {
                  id: true,
                  number: true,
                  unitId: true
                },
                include: 'unit'
              }
            }).$promise
        }
      })
      .then(async res => {
        return res
      })
  }

  const splitSession = async function splitSession (
    session,
    workorder,
    isSessionStart = false,
    errorHandler = null
  ) {
    let sessionItems = []
    let shouldSplitItems = false
    if (workorder.isSerial) {
      if (session.stocks?.length > 0) {
        if (
          session.stocks.length === session.quantity &&
          session.stocks.every(stock => !stock.serial)
        ) {
          sessionItems = [session]
          shouldSplitItems = true
        } else {
          for (let i = 0; i < session.quantity; i++) {
            if (session.stocks[i]) {
              sessionItems.push({
                ...session.stocks[i],
                quantity: 1
              })
            } else {
              sessionItems.push({
                id: `fake-stock-id-${i}`,
                quantity: 1,
                serial: null,
                indicator: session.indicator
              })
            }
          }
        }
      } else {
        sessionItems = [session]
        shouldSplitItems = true
      }
    } else {
      if (session.stocks?.length > 0) {
        sessionItems = session.stocks
      } else {
        sessionItems = [session]
      }
    }
    console.log('sessionItems', sessionItems)
    let items = await splitItemsDialog(
      sessionItems,
      workorder.sku,
      isSessionStart ? 'start' : 'split'
    )

    if (shouldSplitItems) {
      const item = items[0]
      items = []
      let quantity = item.newStockQuantity
      for (let i = 0; i < item.newStockQuantity; i++) {
        const stock = session.stocks?.[i] ? session.stocks[i] : null
        const newQuantity = quantity > 1 ? 1 : quantity
        quantity -= newQuantity
        items.push({
          id: stock?.id ? stock.id : `fake-stock-id-${i}`,
          displayName: `${i + 1}(${session.indicator})`,
          quantity: newQuantity,
          newStockQuantity: newQuantity,
          quantityLeft: 0,
          formsAction: 'move'
        })
      }
    }
    console.log('items', items)
    if (isSessionStart) {
      const overallQuantity = sessionItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      )
      const itemsNewStockQuantity = items.reduce(
        (acc, item) => acc + item.newStockQuantity,
        0
      )
      console.log('overallQuantity', overallQuantity)
      if (overallQuantity === itemsNewStockQuantity) {
        return {
          sessionId: session.id,
          indicator: session.indicator
        }
      }
    }
    return Workorder.splitSession({
      workorderId: workorder.id,
      items,
      sourceSessionId: session.id,
      isStocks: session.stocks?.length > 0,
      createStock: workorder.createStock
    })
      .$promise.then(function (res) {
        return res
      })
      .catch(function (err) {
        if (errorHandler) {
          errorHandler(err)
        } else {
          console.error(err)
        }
      })
  }

  const handleGeneratedSerialsErrors = function handleGeneratedSerialsErrors (
    generatedSerialsErrors
  ) {
    if (generatedSerialsErrors.length > 0) {
      console.error('errors', generatedSerialsErrors)
      const messages = []
      for (const error of generatedSerialsErrors) {
        if (
          error.code &&
          [
            'GENERATE_SERIALS_UNIQUENESS_ERROR',
            'PART_GENERATE_SERIALS_NOT_SET'
          ].includes(error.code)
        ) {
          messages.push($translate.instant(`WF.${error.code}`, error.details))
        } else {
          messages.push(error.message)
        }
      }
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'error-toast',
        hideDelay: 20000
      })
      $mdToast.show(mdToast)
      $mdToast.updateTextContent(messages.join('\n'))
    }
  }

  return {
    deleteWorkorder,
    generateWorkorderColumns,
    generateStatusTemplate,
    splitSession,
    splitItemsDialog,
    handleGeneratedSerialsErrors
  }
}

module.exports = WorkorderUtils
